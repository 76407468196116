const axios = require('axios').default;

module.exports = {
    create(payload) {
      let rol = "0"
      if(payload.roles.includes("Admin") == true && payload.roles.includes("Marketer") == true && payload.roles.includes("Designer") == true && payload.roles.includes("Copy Writter") == true){
         rol = {"rol":"8"}
         payload = Object.assign(payload, rol);
      }else if(payload.roles.includes("Admin") == true && payload.roles.includes("Designer") == true && payload.roles.includes("Copy Writter") == true){
         rol = {"rol":"7"}
         payload = Object.assign(payload, rol);
      }
      else if(payload.roles.includes("Admin") == true && payload.roles.includes("Marketer") == true && payload.roles.includes("Copy Writter") == true){
         rol = {"rol":"6"}
         payload = Object.assign(payload, rol);
      }
      else if(payload.roles.includes("Admin") == true && payload.roles.includes("Marketer") == true && payload.roles.includes("Designer") == true){
         rol = {"rol":"5"}
         payload = Object.assign(payload, rol);
      }
      else if(payload.roles.includes("Admin") == true && payload.roles.includes("Copy Writter") == true){
         rol = {"rol":"4"}
         payload = Object.assign(payload, rol);
      }
      else if(payload.roles.includes("Admin") == true && payload.roles.includes("Designer") == true){
         rol = {"rol":"3"}
         payload = Object.assign(payload, rol);
      }
      else if(payload.roles.includes("Admin") == true && payload.roles.includes("Marketer") == true){
         rol = {"rol":"2"}
         payload = Object.assign(payload, rol);
      }
      else if(payload.roles.includes("Designer") == true && payload.roles.includes("Marketer") == true && payload.roles.includes("Copy Writter") == true){
         rol = {"rol":"12"}
         payload = Object.assign(payload, rol);
      }
      else if(payload.roles.includes("Marketer") == true && payload.roles.includes("Copy Writter") == true){
         rol = {"rol":"11"}
         payload = Object.assign(payload, rol);
      }
      else if(payload.roles.includes("Marketer") == true && payload.roles.includes("Designer") == true){
         rol = {"rol":"10"}
         payload = Object.assign(payload, rol);
      }
      else if(payload.roles.includes("Copy Writter") == true && payload.roles.includes("Designer") == true){
         rol = {"rol":"14"}
         payload = Object.assign(payload, rol);
      }
      else if(payload.roles.includes("Admin") == true){
         rol = {"rol":"1"}
         payload = Object.assign(payload, rol);
      }
      else if(payload.roles.includes("Designer") == true){
         rol = {"rol":"15"}
         payload = Object.assign(payload, rol);
      }
      else if(payload.roles.includes("Copy Writter") == true){
         rol = {"rol":"13"}
         payload = Object.assign(payload, rol);
      }
      else if(payload.roles.includes("Marketer") == true){
         rol = {"rol":"9"}
         payload = Object.assign(payload, rol);
      }
         axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/user/create`, payload)
        .then(response => {
           return response
        }).catch(error => {
            return error
         })
},
async dataUser(){
   let data = JSON.parse(sessionStorage.getItem('authUser'));
   let payload = {"id_firebase":data.uid}
   try {
      const apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/user/find/id/`, payload) 
      return apiResponse
    } catch (e) {
      return e
   }
},
async validateClient(email, password){
   let payload = {
      "email":email,
      "password":password,            
}
   try {
      const apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/client/user/validate`, payload) 
      return apiResponse
    } catch (e) {
      return e
   }
}

}