import Vue from 'vue'
import Vuex from 'vuex'

import modules from './modules'
import firebase from 'firebase/app';
import "firebase/auth";
const userController = require('@/controllers/endpoint/user')

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
  state: {
    error: null,
  },
  mutations: {
    new_User_Error(state, payload){
      state.error = payload
    },
  },
  actions: {
    newUser({commit}, user){
      let authUser = sessionStorage.getItem('authUser');
      firebase.auth().createUserWithEmailAndPassword(user.email, user.password)
        .then(response => {
          const uid = {id_firebase:response.user.uid};
          let userData = Object.assign(user, uid);
          userController.create(userData) 

          commit('new_User_Error', "New user successfully created")
          sessionStorage.setItem("authUser", authUser);
        })
        .catch(error => {
          commit('new_User_Error', error.message)
        })
    },
  },
})

export default store

